<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Filters
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          ></span
        >
      </div>
      
    </div>
    <form class="form">
      <div class="card-body">
        
	

		<div id="app">
		
		<div class="repeater" v-if="repeaterData">
				<table class="table table-responsive">
					<thead>
					<tr>
					  <th></th>
					  <th>Type</th>
					  <th>Field</th>
					  <th>Operator</th>
					  <th>Type</th>
            <th>Value</th>
					  <th></th>
					</tr>
				  </thead>
					<tbody>
						
						<tr :value="repeaterData" v-for="(col, index) in repeaterData" :key="index">
							<td><span class="input-group-btn-vertical">
							<a style="cursor: pointer;" v-on:click="positionUp(index)" class="link"><i class="fa fa-arrow-up"></i></a><br/>
							<a style="cursor: pointer;" v-on:click="positionDown(index)" class=""><i class="fa fa-arrow-down"></i></a>
							</span>
							</td>
							<td>
							  <el-select v-model="col.type" :placeholder="col.type">
									<el-option
									  v-for="item in options_col_type"
									  :key="item.value"
									  :label="item.text"
									  :value="item.value">
									</el-option>
								  </el-select>
							  
							</td>
							<td><el-select v-model="col.field" :placeholder="col.field">
									<el-option
									  v-for="item in options_col_field"
									  :key="item.value"
									  :label="item.text"
									  :value="item.value">
									</el-option>
								  </el-select></td>
							<td>
							<el-select v-model="col.operator" :placeholder="col.operator">
									<el-option
									  v-for="item in options_col_operator"
									  :key="item.value"
									  :label="item.text"
									  :value="item.value">
									</el-option>
								  </el-select>
							</td>
              <td><el-select v-model="col.method" :placeholder="col.method">
																	<el-option
																	  v-for="item in options_col_method"
																	  :key="item.value"
																	  :label="item.text"
																	  :value="item.value">
																	</el-option>
																  </el-select></td>
							<td><input v-if="col.type == 'id'" class="form-control form-control-solid" disabled="disabled" type="text" value="<current id>">
							<input v-if="col.type == 'value' || col.type == 'routeData' || col.type == 'customValue' || col.type == 'storeData'" class="form-control form-control-solid" type="text" v-model="col.value">
							</td>
						
							
							
							<td><input class="form-control form-control-solid" type="hidden" :value="JSON.stringify(col.options)" ></td>
							
							
							<td><a class="btn btn-danger btn-sm" v-on:click="remove($event, index)"><i class="fa fa-trash"></i></a></td>
						</tr>
						<tr><td colspan="6"></td><td><button
							  type="button"
							  class="btn btn-sm btn-secondary"
							  v-on:click="generate"
							>
							 <i class="fa fa-plus"></i>
							</button></td></tr>
					</tbody>
				</table>
				
			</div>
			
	
              <input 
                class="form-control form-control-lg form-control-solid"
                ref="Filters"
                type="hidden"
				:value="JSON.stringify(repeaterData)"
              />
			  

			  
      </div>

      </div>
	  <div class="card-footer d-flex justify-content-end py-6 px-9">
	  <span :class="spinner"></span>
      <button v-if="save_button"
        type="button"
        class="btn btn-primary"
        @click="saveTrigger()"
        ref="kt_save_changes"
      >
      Save Changes
      </button>
    </div>
    </form>
  </div>
</template>

<script>
	import axios from 'axios'
	
    export default {
       data(){
           return {
			  repeaterData: null,
			  options_col_type: [
				  { value: null, text: '' },
				  { value: 'id', text: 'Route ID' },
          { value: 'routeData', text: 'Route Data' },
          { value: 'storeData', text: 'Store Data' },
				  { value: 'customValue', text: 'Custom value' },
				  // { value: 'parentValue', text: 'Parent value' },
				  { value: 'manyValues', text: 'Many values'},
					{ value: 'parentRenderId', text: 'Parent Render ID'}
				],
			  options_col_operator: [
				  { value: null, text: '' },
				  { value: '=', text: 'Equal' },
				  { value: '!=', text: 'Not equal' },
				  { value: 'like', text: 'Contains' },
				  { value: 'not_like', text: 'Does not contain' },
				  { value: 'where_in', text: 'Where In'},
				  { value: '>', text: 'Greater then' },
				  { value: '<', text: 'Lesser then' },
				  { value: '<>', text: 'Between' },
				  { value: 'array_contains', text: 'Array contains'},
          { value: 'IS NULL', text: 'Is null' }
				],
        			options_col_method: [
			  { value: null, text: '' },
			  { value: 'and', text: 'AND' },
				{ value: 'or', text: 'OR' },
			],
			  options_col_field: [],
              spinner: '',
              save_button: true,
              uid: this.$route.params.uid,
			  ajaxData: {"component":{"filters":null}}
           }
       },
		methods:{
			async saveChanges () {
			  this.spinner = 'spinner spinner-sm spinner-success spinner-right'
			  this.save_button = false
			  axios({
				method: 'put',
				url: 'https://keetels-api.eliteswitch.com/api/component/'+this.uid,
				data: {
					filters: this.$refs.Filters.value
				  }
			  }).then((response) => {
				this.ajaxData = response.data.result
				this.spinner = ''
				this.save_button = true
			  }, (error) => {
				console.log(error)
			  })
			},
			async refreshTable () {
			  this.spinner = 'spinner spinner-sm spinner-success spinner-right'
			  this.repeaterData = null
			  this.save_button = false
			  axios({
				method: 'get',
				url: 'https://keetels-api.eliteswitch.com/api/component/'+this.uid
			  }).then((response) => {
			    this.repeaterData = JSON.parse(response.data.result.item.filters)
				if(response.data.result.item.filters == null){
					this.repeaterData = []
				}
				this.ajaxData = response.data.result
				this.spinner = ''
				this.save_button = true
			  }, (error) => {
				console.log(error)
			  })
			},
			saveTrigger() {
			  this.ajaxData.item.filters = this.$refs.Filters.value
			  this.saveChanges()
			},
			positionUp: function(val){
				if( val == 0){
					return false;
				}
				const newArray = this.repeaterData;
				const new_index = (val - 1);
				const current_index = val;
			
				if (new_index >= newArray.length) {
					let k = new_index - newArray.length + 1;
					while (k--) {
						newArray.push(undefined);
					}
				}
				newArray.splice(new_index, 0, newArray.splice(current_index, 1)[0]);
			},
			positionDown: function(val){
				if( val == (this.repeaterData.length - 1)){
					return false;
				}
				// alert(this.repeaterData.length+' - '+val)
				const newArray = this.repeaterData;
				const new_index = (val + 1);
				const current_index = val;
			
				if (new_index >= newArray.length) {
					let k = new_index - newArray.length + 1;
					while (k--) {
						newArray.push(undefined);
					}
				}
				newArray.splice(new_index, 0, newArray.splice(current_index, 1)[0]);
			},
			generate: function(){
				this.repeaterData.push({type:'', field:'', title: '', method: ''})
			},
			remove: function(e, index){
			// alert(index);
				this.repeaterData.splice(index, 1);
	
			},
			change_option(a, b, c){
				if(Object.prototype.hasOwnProperty.call(this.repeaterData[a],'options')){
					this.repeaterData[a].options[c] = b
					this.positionUp(0)
					// this.positionDown(0)
				} else{
					this.repeaterData[a].options = {}
					this.repeaterData[a].options[c] = b
					this.positionUp(0)
					// this.positionDown(0)
				}
			},
			options_continue(a){
				if(Object.prototype.hasOwnProperty.call(this.repeaterData[a],'options')){
					if(this.repeaterData[a].options['selection'] == null){
						this.repeaterData[a].options['link'] = ''
						this.repeaterData[a].options['field'] = ''
						this.positionUp(0)
					}
				}
			},
			select_option(a, b, c){
				if(!Object.prototype.hasOwnProperty.call(this.repeaterData[a],'options')){
					this.repeaterData[a].options = {}
					this.repeaterData[a].options[c] = b
					this.positionUp(0)
				}
			},
			checkOptions(obj, check){
				if(Object.prototype.hasOwnProperty.call(obj, 'options')){
					if(Object.prototype.hasOwnProperty.call(obj.options, check)){
						return obj.options[check];
					}
				}
			}
		},
		watch: {
			ajaxData: function (val) {
				this.spinner = 'spinner spinner-sm spinner-success spinner-right'
				this.save_button = false
				axios({
					method: 'options',
					url: 'https://keetels-api.eliteswitch.com/api/'+val.item.endpoint
				  }).then((response) => {
					if(response.data.result){
					 for (const field in response.data.result.data.fields) {
						this.options_col_field.push({value: field, text: field})
					 }
					}
					
					 this.spinner = ''
					 // if(response.data.result.length > 0){
						this.save_button = true
					 // }
					 

				  }, (error) => {
					console.log(error)
				  })
			
			},
		  },
		created: function(){
			this.repeaterData = [];
		},
		mounted () {
			this.refreshTable()
		  },
    }
	
</script>
